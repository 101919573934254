import { createStore } from 'vuex';
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.headers.get = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}
axios.defaults.headers.delete = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}
axios.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      Authorization: localStorage.getItem('__token') ? `Bearer ${localStorage.getItem('__token')}` : ''
    }
  }
});
export default createStore({
  state: {
    user: null,
    auth: false,
    role: null,
    isSuper: false,
    permits: {
      pagos: false,
      menus: false,
      baneo: false
    },
    routes: {},
    basic: {},
    entity: {},
    entities: {},
    tabs: {},
    tarifas: []
  },
  getters: {
    user(state) {
      return state.user
    },
    role(state) {
      return state.role
    },
    isSuper(state) {
      return state.isSuper
    },
    permits(state) {
      return state.permits
    },
    auth(state) {
      return state.auth
    },
    basic: (state) => state.basic,
    entity: (state) => state.entity,
    entities: (state) => state.entities,
    routes: (state) => state.routes,
    tabs: (state) => state.tabs,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      state.role = user ? user.role : null;
      state.auth = Boolean(user);
      state.isSuper = user && user.role && (user.id === process.env.VUE_APP_ID_SUPER || user.role === 'SUPER')
      const idsPermitidos = process.env.VUE_APP_IDS_PAGOS ? JSON.parse(process.env.VUE_APP_IDS_PAGOS) : [];
      state.permits.pagos = user && user.role && (idsPermitidos.includes(user.id) || user.id === process.env.VUE_APP_ID_SUPER || user.role === 'SUPER');
      state.permits.menus = user && user.role && user.role !== 'USER'
      state.permits.baneo = state.isSuper;
    },
    SET_ROUTES: (state, {data, entity}) => state.routes[entity] = data,
    SET_SETTING: (state, {data, setting}) => state.basic[setting] = data,
    SET_ENTITY: (state, {data, entity}) => state.entity[entity] = data,
    SET_ENTITIES: (state, {data, entity}) => state.entities[entity] = data,
    SET_TABS: (state, {data, entity}) => state.tabs[entity] = data,
  },
  actions: {
    get: async ({ dispatch }, route) => {
      const [err, res] = await axios
        .get(route)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      return data;
    },
    async login( { dispatch }, credentials ) {
      await axios.get(`${process.env.VUE_APP_API}/sanctum/csrf-cookie`);
      const [err, res] = await axios
        .post(`${process.env.VUE_APP_API}/api/login`, credentials)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (!data.error) {
        localStorage.setItem('__token', data.token);
        dispatch('getUser');
      }
      return data;
    },
    async logout( { dispatch } ) {
      await axios.post(`${process.env.VUE_APP_API}/api/logout`);
      return dispatch('getUser');
    },
    async __init( { dispatch, getters } ) {},
    ///
    getSetting: async ({ commit }, setting) => {
      const [err, res] = await axios
        .get(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/settings/nombre/${setting}?soloElementos=1&conValor=1&conDescripcion=1`)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (!data.error) {
        commit('SET_SETTING', {data, setting});
      }
    },
    getForce: async({ commit, getters }, entity) => {
      if (!getters.routes[entity]) {
        console.error(`Dato no encontrado en STORE : ${entity}`)
        return;
      }
      const url = getters.routes[entity].url;
      const [err, res] = await axios
        .get(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (!data.error) {
        commit('SET_ENTITIES', {data, entity});
      }
    },
    resetAll: ({ commit }, {entity}) => {
      commit('SET_ENTITIES', {data: null, entity});
    },
    getAll: async ({ commit, getters }, {url, query = {}, entity = null}) => {
      const aux = Object.keys(query).map(key => {
        if (query[key]) {
          return key + '=' + query[key]
        } else {
          return key
        }
      }).join('&');
      url += aux != '' ? `?${aux}` : '';
      if (
        entity &&
        getters.routes[entity] &&
        query.all !== undefined
      ) {
        //console.warn(`En STORE : ${url}`, getters.routes[entity])
        return;
      }
      if (entity) {
        commit('SET_ROUTES', {data: {url, query}, entity});
      }
      const [err, res] = await axios
        .get(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (!data.error && entity) {
        commit('SET_ENTITIES', {data, entity});
      }
      if (!entity) {
        return data
      }
    },
    getOne: async ({ commit }, {url, entity}) => {
      const [err, res] = await axios
        .get(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (!data.error) {
        commit('SET_ENTITY', {data: data.elementos, entity});
      } else {
        commit('SET_ENTITY', {data: null, entity});
        window.__error = data
      }
    },
    getUser: async ({ commit, dispatch }) => {
      const data = await dispatch('get', `${process.env.VUE_APP_API}/api/user`);
      commit('SET_USER', data.user);
    },
    /** SET */
    set: async ({ getters }, {data, entity}) => {
      const index = getters.entities[entity].findIndex(x => x.id === data.id)
      getters.entities[entity].splice(index, 1, data);
    },
    /** PUT */
    putSetting: async ({ commit }, {id, form = {}}) => {
      const [err, res] = await axios
        .put(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/settings/${id}?soloElementos=1&conValor=1`, form)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (!data.error) {
        commit('SET_SETTING', {data: data.elementos, setting: form.nombre});
      }
      return data;
    },
    put: async ({ dispatch }, {url, form = {}, entity = null}) => {
      const [err, res] = await axios
        .put(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`, form)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (
        !data.error &&
        entity
      ) {
        await dispatch('getForce', entity);
      }
      return data;
    },
    /** DELETE */
    delete: async ({ dispatch }, {url, form = {}, entity = null}) => {
      const [err, res] = await axios
        .delete(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`, { data: form })
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (
        !data.error &&
        entity
      ) {
        await dispatch('getForce', entity);
      }
      return data;
    },
    /** POST */
    buildFormData: async ({ dispatch }, {formData, data, parentKey = null}) => {
      if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach(async (key) => {
          await dispatch('buildFormData', {formData, data: data[key], parentKey: parentKey ? `${parentKey}[${key}]` : key})
        });
      } else {
        const value = data == null ? '' : (typeof data === 'boolean' ? Number(data) : data);
        formData.append(parentKey, value);
      }
    },
    jsonToFormData: async ({ dispatch }, {data}) => {
      const formData = new FormData();
      await dispatch('buildFormData', {formData, data});
      return formData;
    },
    post: async ({ dispatch }, {url, form, entity = null}) => {
      const formData = await dispatch('jsonToFormData', {data: form});
      const [err, res] = await axios
        .post(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`, formData)
        .then(v => [null, v], err => [err, null]);
      const { data } = err ? err.response : res;
      if (
        !data.error &&
        entity
      ) {
        await dispatch('getForce', entity);
      }
      return data;
    },
    /** PATCH */
    patch: async ({ dispatch }, {url, form = {}}) => {
      const { data } = await axios.patch(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`, form);
      return data;
    },
    /** GET */
    getCommon: async ({ dispatch }, url) => {
      const { data } = await axios.get(`${process.env.VUE_APP_API}${process.env.VUE_APP_PANEL}/${url}`);
      return data;
    },
    ///

  },
  modules: {
  }
})
