import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const dateFilter = date => {
  const getYear = date.toLocaleString("default", { year: "numeric" });
  const getMonth = date.toLocaleString("default", { month: "2-digit" });
  const getDay = date.toLocaleString("default", { day: "2-digit" });
  return `${getYear}-${getMonth}-${getDay}`;
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/login.vue'),
    meta: {
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/HomeView.vue'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/excepciones',
    name: 'excepciones',
    component: () => import(/* webpackChunkName: "excepciones" */ '../views/Basic/ExcepcionView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/estadisticas',
    name: 'estadisticas',
    component: () => import(/* webpackChunkName: "estadisticas" */ '../views/Basic/EstadisticasView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '../views/Basic/TicketsView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/avisos',
    name: 'avisos',
    component: () => import(/* webpackChunkName: "avisos" */ '../views/Avisos/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/aviso/:id',
    component: () => import(/* webpackChunkName: "aviso" */ '../views/Avisos/EditView'),
    name: 'avisoEditar',
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/enviador_propiedades',
    name: 'emails',
    component: () => import(/* webpackChunkName: "emails" */ '../views/Basic/RenovacionesView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/propiedades',
    name: 'propiedades',
    component: () => import(/* webpackChunkName: "listadoPropiedades" */ '../views/Propiedades/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/propiedad',
    component: () => import(/* webpackChunkName: "propiedad" */ '../views/Propiedades/ElementView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
    children: [
      {
        path: ':code/editar',
        name: 'propiedadEditar',
        component: () => import(/* webpackChunkName: "editarPropiedad" */ '../views/Propiedades/EditView'),
      },
      {
        path: 'nueva',
        name: 'propiedadNueva',
        component: () => import(/* webpackChunkName: "nuevaPropiedad" */ '../views/Propiedades/EditView'),
      },
    ],
  },
  {
    path: '/registros',
    name: 'registros',
    component: () => import(/* webpackChunkName: "listadoRegistros" */ '../views/Clientes/RegistrosView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import(/* webpackChunkName: "listadoClientes" */ '../views/Clientes/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/clientes/editados',
    name: 'clientesEditados',
    component: () => import(/* webpackChunkName: "clientesEditados" */ '../views/Clientes/EditadosView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/cliente',
    component: () => import(/* webpackChunkName: "cliente" */ '../views/Clientes/ElementView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
    children: [
      {
        path: ':id/editar',
        name: 'clienteEditar',
        props: true,
        component: () => import(/* webpackChunkName: "editarCliente" */ '../views/Clientes/EditView'),
      },
      {
        path: 'nueva',
        name: 'clienteNuevo',
        component: () => import(/* webpackChunkName: "nuevoCliente" */ '../views/Clientes/EditView'),
      },
    ],
  },
  {
    path: '/pagos',
    name: 'pagos',
    component: () => import(/* webpackChunkName: "pagos" */ '../views/Propiedades/Pagos/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/precios_temporada',
    name: 'precios_temporada',
    component: () => import(/* webpackChunkName: "preciosTemporada" */ '../views/PreciosTemporada/ElementView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/paginas',
    name: 'paginas',
    component: () => import(/* webpackChunkName: "listadoPaginas" */ '../views/Paginas/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/pagina',
    component: () => import(/* webpackChunkName: "pagina" */ '../views/Paginas/ElementView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
    children: [
      {
        path: ':id/editar',
        name: 'paginaEditar',
        props: true,
        component: () => import(/* webpackChunkName: "editarPagina" */ '../views/Paginas/EditView'),
      },
      {
        path: 'nueva',
        name: 'paginaNueva',
        component: () => import(/* webpackChunkName: "nuevoPagina" */ '../views/Paginas/EditView'),
      },
    ],
  },
  {
    path: '/estados',
    name: 'estados',
    component: () => import(/* webpackChunkName: "estados" */ '../views/Estados/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/elementos',
    name: 'elementos',
    component: () => import(/* webpackChunkName: "elementos" */ '../views/Elementos/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/localidades',
    name: 'localidades',
    component: () => import(/* webpackChunkName: "localidades" */ '../views/Localidades/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/barrios',
    name: 'barrios',
    component: () => import(/* webpackChunkName: "barrios" */ '../views/Barrios/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/medios_pago',
    name: 'medios_pago',
    component: () => import(/* webpackChunkName: "medios_pago" */ '../views/MediosPago/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/tipos_propiedades',
    name: 'tipos_propiedades',
    component: () => import(/* webpackChunkName: "tipos_propiedades" */ '../views/Basic/TipoPropiedadView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/tipos_tickets',
    name: 'tipos_tickets',
    component: () => import(/* webpackChunkName: "tipos_tickets" */ '../views/Basic/TipoTicketView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/fotos_a_cambiar',
    name: 'fotos_a_cambiar',
    component: () => import(/* webpackChunkName: "fotos_a_cambiar" */ '../views/Basic/FotosCambiarView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/ListView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/tarifa',
    component: () => import(/* webpackChunkName: "tarifa" */ '../views/Tarifas/ElementView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER'],
      transition: 'slide-left'
    },
    children: [
      {
        path: ':id/editar',
        name: 'tarifaEditar',
        props: true,
        component: () => import(/* webpackChunkName: "editarCliente" */ '../views/Tarifas/EditView'),
      },
      {
        path: 'nueva',
        name: 'tarifaNueva',
        component: () => import(/* webpackChunkName: "nuevoCliente" */ '../views/Tarifas/EditView'),
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Basic/ErrorView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/sin_acceso',
    name: 'sin_acceso',
    component: () => import(/* webpackChunkName: "sinAcceso" */ '../views/Basic/SinAccesoView'),
    meta: {
      requiresAuth: true,
      roles: ['ADMIN', 'SUPER', 'USER'],
      transition: 'slide-left'
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'ErrorPage',
    component: () => import(/* webpackChunkName: "errorPage" */ '../views/Basic/Error404View'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = process.env.VUE_APP_TITLE
  if (!store.getters.user) {
    await store.dispatch('getUser');
  }
  const isAuthenticated = store.getters.auth;
  if (to.meta.requiresAuth && !isAuthenticated)
  {
    next({
      name: 'login'
    });
    return
  } else {
    if (
      isAuthenticated &&
      to.meta.roles &&
      to.meta.roles.indexOf(store.getters.role) < 0
    ) {
      next({
        name: 'sin_acceso'
      });
      return
    }
    if (
      from.name === 'propiedadEditar' &&
      to.name === 'propiedadNueva' ||
      from.name === 'propiedadEditar' &&
      to.name === 'propiedadEditar'
    ) {
      next({
        name: 'propiedades'
      });
      return;
    }
    if (
      from.name === 'paginaEditar' &&
      to.name === 'paginaNueva' ||
      from.name === 'paginaEditar' &&
      to.name === 'paginaEditar'
    ) {
      next({
        name: 'paginas', query: {'estado': 'habilitado'}
      });
      return;
    }
    /** <EDIT> */
    if (
      to.name === 'clienteEditar' ||
      to.name === 'clienteNuevo'
    ) {
      let tabAux = [
        { name: 'basico', label: 'General' },
        { name: 'contactos', label: 'Contactos' },
      ]
      if (!store.getters.entities.baja__list) { await store.dispatch('getAll', {url: `baja_motivos`, query: {'all': 1, 'soloElementos': 1}, entity: 'baja__list'}) }
      if (!store.getters.entities.modelos_email__list) { store.dispatch('getAll', {url: `modelos_email`, query: {'all': 1, 'soloElementos': 1, 'estado': 1}, entity: 'modelos_email__list'}) }
      await store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'CONTACTO', 'notId': [13]}, entity: 'elementos__contacto'})
      if (!store.getters.entities.estados__list) { store.dispatch('getAll', {url: `estados`, query: {'all': 1, 'not': '4-5-9', 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'estados__list'}) }
      if (to.params.id) {
        tabAux.push({ name: 'propiedades', label: 'Propiedades' })
        tabAux.push({ name: 'tickets', label: 'Historial' })
        await store.dispatch('getOne', {url: `clientes/${to.params.id}`, entity: 'cliente'});
        if (!store.getters.entity.cliente) {
          next({
            name: 'error'
          });
          return;
        }
        document.title = `${process.env.VUE_APP_TITLE} - ${store.getters.entity.cliente.nombre}`
        if (!store.getters.entities.tipos_ticket__list) { store.dispatch('getAll', {url: `tipos_ticket`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'tipos_ticket__list'}) }
        if (!store.getters.entities.users__list) { store.dispatch('getAll', {url: `users`, query: {'all': 1, 'soloElementos': 1}, entity: 'users__list'}) }
        if (!store.getters.entities.clientes__list) { store.dispatch('getAll', {url: `clientes`, query: {'all': 1, 'soloElementos': 1, 'limitado': null}, entity: 'clientes__list'}) }
      } else {
        store.commit('SET_ENTITY',
          {
            data: {
              id: null,
              nombre: '',
              username: '',
              contacto: '',
              url: null,
              agenda__id: null,
              tipo: 'CLIENTE',
              verificado: false,
              confirmado: false,
              contactos: [],
              verificacion: {
                dni: '',
                dni_imagen: '',
                dni_fotocopia: false,
                foto: ''
              },
              obs: '',
              propiedades: [],
              password: '',
              archivos: {
                foto: null,
                dni: null
              }
            },
            entity: 'cliente'
          }
        );
      }
      store.commit('SET_TABS',
        {
          data: tabAux,
          entity: 'cliente'
        }
      );
    }
    if (
      to.name === 'tarifaEditar' ||
      to.name === 'tarifaNueva'
    ) {
      if (!store.getters.basic.TEMPORADA) { store.dispatch('getSetting', 'TEMPORADA') }
      if (to.params.id) {
        store.dispatch('getOne', {url: `tarifas/${to.params.id}`, entity: 'tarifa'});
      } else {
        if (!store.getters.basic.TEMPORADA) { await store.dispatch('getSetting', 'TEMPORADA') }
        store.commit('SET_ENTITY',
          {
            data: {
              id: null,
              orden: 0,
              titulo: '',
              temporada: store.getters.basic.TEMPORADA.valor,
              estructura: []
            },
            entity: 'tarifa'
          }
        );
      }
    }
    if (
      to.name === 'paginaEditar' ||
      to.name === 'paginaNueva'
    ) {
      let tabAux = [
        { name: 'basico', label: 'General' },
        { name: 'contactos', label: 'Contactos' },
        { name: 'redes', label: 'Redes sociales' },
        { name: 'propiedades', label: 'Propiedades' },
      ]
      await store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'CONTACTO', 'notId': [9, 11, 13]}, entity: 'elementos__contacto'})
      if (!store.getters.entities.clientes__list) { store.dispatch('getAll', {url: `clientes`, query: {'all': 1, 'soloElementos': 1, 'limitado': null}, entity: 'clientes__list'}) }
      if (!store.getters.entities.elementos__social) { store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'SOCIAL'}, entity: 'elementos__social'}) }
      if (!store.getters.entities.elementos__pagina) { store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'CONTACTO', 'notId': [9, 11, 13]}, entity: 'elementos__pagina'}) }
      if (!store.getters.entities.elementos__google) { store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'GOOGLE'}, entity: 'elementos__google'}) }
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      if (to.params.id) {
        await store.dispatch('getOne', {url: `paginas/${to.params.id}`, entity: 'pagina'});
        if (!store.getters.entity.pagina) {
          next({
            name: 'error'
          });
          return;
        }
        if (!store.getters.basic.COLORES) { await store.dispatch('getSetting', 'COLORES') }
        if (!store.getters.entity.pagina.ftp) {
          store.getters.entity.pagina.ftp = {
            user: null,
            password: null
          }
        }
        tabAux.push({ name: 'estadisticas', label: 'Estadísticas' })
      } else {
        store.commit('SET_ENTITY',
          {
            data: {
              id: null,
              nombre: null,
              contactos: [],
              estado: false,
              elementos: {},
              estructuras: {
                activo: true,
                descripcion: null,
                hero: null,
                logo: null,
                propiedades_home: 0,
                tags: null,
                template: 1,
                titulo: null
              },
              ftp: {
                password: null,
                user: null
              },
              url: null,
              google: [],
              nombre: null,
              propiedades: [],
              otras_propiedades: [],
              redes: [],
              cliente: {},
              cliente__id: null
            },
            entity: 'pagina'
          }
        );
      }
      store.commit('SET_TABS',
        {
          data: tabAux,
          entity: 'pagina'
        }
      );
      if (!store.getters.entities.elementos__list) { await store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1}, entity: 'elementos__list'}) }
      if (!store.getters.entities.encaberzados__list) { await store.dispatch('getAll', {url: `paginas/encabezados`, query: {'all': 1, 'soloElementos': 1}, entity: 'encaberzados__list'}) }
      if (!store.getters.entities.templates__list) { await store.dispatch('getAll', {url: `paginas/templates`, query: {'all': 1, 'soloElementos': 1}, entity: 'templates__list'}) }
    }
    if (
      to.name === 'propiedadEditar' ||
      to.name === 'propiedadNueva'
    ) {
      if (
        from &&
        from.name === 'propiedades'
      ) {
        if (Object.keys(from.query).length) {
          localStorage.setItem('filtrosPropiedades', JSON.stringify(from.query));
        }
      }
      let tabAux = [
        { name: 'basico', label: 'General' },
        { name: 'contactos', label: 'Contactos' },
        { name: 'atributos', label: 'Atributos' },
        { name: 'tarifas', label: 'Tarifas' },
        { name: 'redes', label: 'Redes Sociales' }
      ]
      if (!store.getters.basic.CANTIDAD_FOTOS) { await store.dispatch('getSetting', 'CANTIDAD_FOTOS') }
      if (to.params.code) {
        await store.dispatch('getOne', {url: `propiedades/${to.params.code}`, entity: 'propiedad'});
        if (!store.getters.entities.desde_periodo__list) { store.dispatch('getAll', {url: `propiedades/desde_periodo`, query: {'all': 1, 'soloElementos': 1}, entity: 'desde_periodo__list'}) }
        if (!store.getters.entities.redes_sociales__list) { store.dispatch('getAll', {url: `redes_sociales`, query: {'all': 1, 'soloElementos': 1}, entity: 'redes_sociales__list'}) }
        if (!store.getters.basic.COLORES) { await store.dispatch('getSetting', 'COLORES') }
        if (!store.getters.entities.tipos_ticket__list) { store.dispatch('getAll', {url: `tipos_ticket`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'tipos_ticket__list'}) }
        if (!store.getters.entities.users__list) { store.dispatch('getAll', {url: `users`, query: {'all': 1, 'soloElementos': 1}, entity: 'users__list'}) }
        if (!store.getters.entities.disponibilidades__list) { store.dispatch('getAll', {url: `disponibilidades`, query: {'all': 1, 'soloElementos': 1}, entity: 'disponibilidades__list'}) }
        if (!store.getters.entity.propiedad) {
          next({
            name: 'error'
          });
          return;
        }
        tabAux.push({ name: 'fotos', label: 'Fotos' })
        if (store.getters.entity.propiedad.estado.id != 8) {
          tabAux.push({ name: 'reservas', label: 'Reservas' })
          tabAux.push({ name: 'pagos', label: 'Pagos' })
          tabAux.push({ name: 'consultas', label: 'Consultas' })
          tabAux.push({ name: 'estadisticas', label: 'Estadísticas' })
        }
        tabAux.push({ name: 'tickets', label: 'Historial' })
      } else {
        if (!store.getters.basic.TEMPORADA) { await store.dispatch('getSetting', 'TEMPORADA') }
        tabAux.push({ name: 'fotos', label: 'Fotos' })
        const cliente = localStorage.getItem('clienteId_addPropiedad') ? parseInt(localStorage.getItem('clienteId_addPropiedad')) : null
        if (localStorage.getItem('clienteId_addPropiedad')) {
          localStorage.removeItem('clienteId_addPropiedad');
        }
        store.commit('SET_ENTITY',
          {
            data: {
              codigo: null,
              ficha: {},
              pagos: [],
              atributos: [],
              contactos: [],
              fotos: [],
              tarifas: [],
              reservas: [],
              disponibilidades: [],
              cliente,
              tipo_propiedad: null,
              periodo: null,
              adelanto: null,
              estadia: 50,
              contacto: "",
              titulo: "",
              precio_desde: "",
              comodidades: {
                banios: 0,
                capacidad: 1,
                habitaciones: 0,
                jovenes: 0,
                mascotas: 0,
              },
              disponibilidad: "",
              descripcion: "",
              youtube: "",
              destacado: {
                cartel: "",
                descripcion: "",
              },
              direccion: {
                cp: "",
                latitud: -36.69602089926542,
                longitud: -56.683616638183594,
                direccion: "",
                localidad: "",
                localidad_nombre: "",
                barrio: "",
                barrio_nombre: ""
              },
            },
            entity: 'propiedad'
          }
        );
        if (!store.getters.entities.clientes__list) { store.dispatch('getAll', {url: `clientes`, query: {'all': 1, 'soloElementos': 1, 'limitado': null}, entity: 'clientes__list'}) }
      }
      store.commit('SET_TABS',
        {
          data: tabAux,
          entity: 'propiedad'
        }
      );
      await store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'CONTACTO', 'notId': [9, 11]}, entity: 'elementos__contacto'})
      if (!store.getters.basic.TEMPORADA) { await store.dispatch('getSetting', 'TEMPORADA') }
      if (!store.getters.entities.medios_pago__list) { store.dispatch('getAll', {url: `medios_pago`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'medios_pago__list'}) }
      if (!store.getters.entities.atributos__list) { store.dispatch('getAll', {url: `atributos`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'atributos__list'}) }
      if (!store.getters.entities.elementos__ficha) { store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1, 'tipo': 'FICHA'}, entity: 'elementos__ficha'}) }
      if (!store.getters.entities.tarifas_temporada__list) { store.dispatch('getAll', {url: `tarifas`, query: {'all': 1, 'soloElementos': 1, 'temporada': 2021}, entity: 'tarifas_temporada__list'}) }
      if (!store.getters.entities.tipos_propiedades__list) { store.dispatch('getAll', {url: `tipos_propiedades`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'tipos_propiedades__list'}) }
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
    }
    if (to.name === 'avisoEditar') {
      await store.dispatch('getOne', {url: `avisos/${to.params.id}`, entity: 'aviso'});
      if (!store.getters.entities.grupos__list) { store.dispatch('getAll', {url: `grupos`, query: {'all': 1, 'soloElementos': 1}, entity: 'grupos__list'}) }
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      if (!store.getters.entities.tipos_propiedades__list) { store.dispatch('getAll', {url: `tipos_propiedades`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'tipos_propiedades__list'}) }
      if (!store.getters.entity.aviso) {
        next({
          name: 'error'
        });
        return;
      }
    }
    /** </EDIT> */
    /** <GETS> */
    if (to.name !== 'login') {
      store.dispatch('getAll', {url: `portal/admin`, query: {'soloElementos': 1}, entity: 'dashboard'})
    }
    if (to.name === 'dashboard') {
      if (!store.getters.basic.TEMPORADA) { store.dispatch('getSetting', 'TEMPORADA') }
    }
    if (to.name === 'clientes') {
      if (store.getters.entities.clientes) { store.dispatch('resetAll', {entity: 'clientes'}) }
      store.dispatch('getAll', {url: `clientes`, query: {...{'cantidad': 30}, ...to.query}, entity: 'clientes'})
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      if (!store.getters.entities.estados_cliente__list) { store.dispatch('getAll', {url: `clientes/estados`, query: {'all': 1, 'soloElementos': 1}, entity: 'estados_cliente__list'}) }
    }
    if (to.name === 'estadisticas') {
      if (!store.getters.basic.LABEL_GRAFICOS) { await store.dispatch('getSetting', 'LABEL_GRAFICOS') }
      if (!store.getters.basic.COLORES) { await store.dispatch('getSetting', 'COLORES') }
      if (!store.getters.basic.STAT_INFLACION) { await store.dispatch('getSetting', 'STAT_INFLACION') }
      if (!store.getters.basic.STAT_PRECIOS) { await store.dispatch('getSetting', 'STAT_PRECIOS') }
      if (!store.getters.basic.INICIO_TEMPORADA) { await store.dispatch('getSetting', 'INICIO_TEMPORADA') }
      if (!store.getters.basic.FECHA_PROMO) { await store.dispatch('getSetting', 'FECHA_PROMO') }
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
    }
    if (to.name === 'registros') {
      if (!store.getters.basic.TEMPORADA) { store.dispatch('getSetting', 'TEMPORADA') }
      if (store.getters.entities.registros) { store.dispatch('resetAll', {entity: 'registros'}) }
      store.dispatch('getAll', {url: `clientes/registros`, query: {...{'cantidad': 30}, ...to.query}, entity: 'registros'})
    }
    if (to.name === 'precios_temporada') {
      store.dispatch('getAll', {url: `estados`, query: {'all': 1, 'not': '1-7-8', 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'estados__list_2'}) 
      if (!store.getters.entities.settings__precios) { await store.dispatch('getAll', {url: `settings`, query: {'all': 1, 'soloElementos': 1, 'nombres': ['PRECIO', 'PRECIO_ANTICIPADO', 'PRECIO_3_PROP', 'PRECIO_4_PROP', 'PRECIO_INMOBILIARIA'], 'conValor': 1, 'conDescripcion': 1}, entity: 'settings__precios'}) }
      if (!store.getters.entities.settings__estaticos) { await store.dispatch('getAll', {url: `settings`, query: {'all': 1, 'soloElementos': 1, 'nombres': ['TEMPORADA', 'FECHA_PROMO', 'FECHA_PRECIO', 'NOMBRE_TEMPORADA', 'INICIO_TEMPORADA', 'CONTROL_LLAMADOS'], 'conValor': 1, 'conDescripcion': 1}, entity: 'settings__estaticos'}) }
      if (!store.getters.entities.settings__precios_select) { await store.dispatch('getAll', {url: `settings`, query: {'all': 1, 'soloElementos': 1, 'nombres': ['ESTADO_ACT_PROP', 'ESTADO_SUS_PROP', 'ESTADO_NOR_PROP'], 'conValor': 1, 'conDescripcion': 1}, entity: 'settings__precios_select'}) }
      if (!store.getters.basic.TEMPORADA) { store.dispatch('getSetting', 'TEMPORADA') }
    }
    if (to.name === 'pagos') {
      if (!store.getters.basic.TEMPORADA) { store.dispatch('getSetting', 'TEMPORADA') }
      if (!store.getters.entities.medios_pago__list) { store.dispatch('getAll', {url: `medios_pago`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'medios_pago__list'}) }
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      if (store.getters.entities.p_pagos) { store.dispatch('resetAll', {entity: 'p_pagos'}) }
      store.dispatch('getAll', {url: `pagos:propiedades`, query: {...{'cantidad': 20}, ...to.query}, entity: 'p_pagos'})
    }
    if (to.name === 'emails') {
      if (!store.getters.basic.TEMPORADA) { await store.dispatch('getSetting', 'TEMPORADA') }
      if (!store.getters.entities.estados__list) { store.dispatch('getAll', {url: `estados`, query: {'all': 1, 'not': '4-5-9', 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'estados__list'}) }
      if (!store.getters.entities.modelos_email__list) { store.dispatch('getAll', {url: `modelos_email`, query: {'all': 1, 'soloElementos': 1, 'estado': 1}, entity: 'modelos_email__list'}) }
    }
    if (to.name === 'medios_pago') {
      store.dispatch('getAll', {url: `medios_pago`, query: {...{'order': 'nombre_ASC'}, ...to.query}, entity: 'medios_pago'})
    }
    if (to.name === 'tickets') {
      const desdeAux = new Date();
      desdeAux.setMonth(desdeAux.getMonth() - parseInt(process.env.VUE_APP_MONTH_STATISTICS));
      const fechaDesde = dateFilter(new Date(desdeAux))
      const fechaHasta = dateFilter(new Date())
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      if (!store.getters.entities.tipos_ticket__list) { store.dispatch('getAll', {url: `tipos_ticket`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'tipos_ticket__list'}) }
      if (!store.getters.entities.users__list) { store.dispatch('getAll', {url: `users`, query: {'all': 1, 'soloElementos': 1}, entity: 'users__list'}) }
      store.dispatch('getAll', {url: `tickets`, query: {...{cantidad: 30, fechaDesde, fechaHasta}, ...to.query}, entity: 'tickets'})
    }
    if (to.name === 'avisos') {
      store.dispatch('getAll', {url: `avisos`, query: {...{cantidad: 20}, ...to.query}, entity: 'avisos'})
    }
    if (to.name === 'barrios') {
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      store.dispatch('getAll', {url: `barrios`, query: {...{'order': 'alfabeticamente_ASC'}, ...to.query}, entity: 'barrios'})
    }
    if (to.name === 'localidades') {
      store.dispatch('getAll', {url: `localidades`, query: {...{all: 1, 'order': 'alfabeticamente_ASC'}, ...to.query}, entity: 'localidades'})
    }
    if (to.name === 'estados') {
      store.dispatch('getAll', {url: `estados`, query: to.query, entity: 'estados'})
    }
    if (to.name === 'elementos') {
      if (store.getters.entities.elementos) { store.dispatch('resetAll', {entity: 'elementos'}) }
      store.dispatch('getAll', {url: `elementos`, query: {...{'cantidad': 30}, ...to.query}, entity: 'elementos'})
    }
    if (to.name === 'tipos_propiedades') {
      store.dispatch('getAll', {url: `tipos_propiedades`, query: {...{'order': 'nombre_ASC'}, ...to.query}, entity: 'tipos_propiedades'})
    }
    if (to.name === 'excepciones') {
      if (store.getters.entities.excepciones) { store.dispatch('resetAll', {entity: 'excepciones'}) }
      store.dispatch('getAll', {url: `excepciones`, query: {...{'cantidad': 30}, ...to.query}, entity: 'excepciones'})
    }
    if (to.name === 'paginas') {
      if (store.getters.entities.paginas) { store.dispatch('resetAll', {entity: 'paginas'}) }
      store.dispatch('getAll', {url: `paginas`, query: {...{'cantidad': 30}, ...to.query}, entity: 'paginas'})
    }
    if (to.name === 'propiedades') {
      let query = {...{'cantidad': 30}, ...to.query};
      if (
        ['propiedades', 'propiedadEditar', 'propiedadNueva'].indexOf(from.name) >= 0 &&
        localStorage.getItem('filtrosPropiedades')
      ) {
        query = JSON.parse(localStorage.getItem('filtrosPropiedades'))
        query.cantidad = 30;
        localStorage.removeItem('filtrosPropiedades')
        next({name: 'propiedades', query});
        return;
      } else {
        if (localStorage.getItem('filtrosPropiedades')) {
          localStorage.removeItem('filtrosPropiedades')
        }
      }
      if (store.getters.entities.propiedades) { store.dispatch('resetAll', {entity: 'propiedades'}) }
      store.dispatch('getAll', {url: `propiedades`, query, entity: 'propiedades'})
      if (!store.getters.entities.clientes__list) { store.dispatch('getAll', {url: `clientes`, query: {'all': 1, 'soloElementos': 1, 'limitado': null}, entity: 'clientes__list'}) }
      if (!store.getters.entities.modelos_email__list) { store.dispatch('getAll', {url: `modelos_email`, query: {'all': 1, 'soloElementos': 1, 'estado': 1}, entity: 'modelos_email__list'}) }
      if (!store.getters.entities.medios_pago__list) { store.dispatch('getAll', {url: `medios_pago`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'medios_pago__list'}) }
      if (!store.getters.entities.localidades__list) { store.dispatch('getAll', {url: `localidades`, query: {'all': 1, 'soloElementos': 1, 'order': 'alfabeticamente_ASC'}, entity: 'localidades__list'}) }
      if (!store.getters.entities.elementos__list) { store.dispatch('getAll', {url: `elementos`, query: {'all': 1, 'soloElementos': 1}, entity: 'elementos__list'}) }
      if (!store.getters.entities.estados__list) { store.dispatch('getAll', {url: `estados`, query: {'all': 1, 'not': '4-5-9', 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'estados__list'}) }
      if (!store.getters.entities.tipos_propiedades__list) { store.dispatch('getAll', {url: `tipos_propiedades`, query: {'all': 1, 'soloElementos': 1, 'order': 'nombre_ASC'}, entity: 'tipos_propiedades__list'}) }
      if (!store.getters.entities.baja__list) { await store.dispatch('getAll', {url: `baja_motivos`, query: {'all': 1, 'soloElementos': 1}, entity: 'baja__list'}) }
    }
    if (to.name === 'tipos_tickets') {
      store.dispatch('getAll', {url: `tipos_ticket`, query: {...{'order': 'nombre_ASC'}, ...to.query}, entity: 'tipos_ticket'})
    }
    if (to.name === 'settings') {
      if (store.getters.entities.settings) { store.dispatch('resetAll', {entity: 'settings'}) }
      store.dispatch('getAll', {url: `settings`, query: {...{'cantidad': 30, 'conValor': null}, ...to.query}, entity: 'settings'})
    }
    /** </GETS> */
    next((vm) => {
      console.log("aca")
      console.log(vm.store)
    });
  }

});

export default router
