<template>
  <div>
    <router-view :key="$route" v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>
<script>
export default {
  name: 'layout',
  components: {}
};
</script>
